<template>
  <div class="all loginBg">
    <div class="login-box">
      <div class="login_logo">
        <h3>MediBook</h3>
        <h4>Control Center</h4>
      </div>
      <el-form :model="form" :rules="rules" ref="form" status-icon class="login-form">
        <el-form-item prop="login_account">
          <el-input v-model="form.login_account"  autocomplete="off" prefix-icon="el-icon-user" v-on:keyup.enter.native="actionData" placeholder="請輸入帳號"></el-input>
        </el-form-item>
        <el-form-item prop="login_pwd">
          <el-input v-model="form.login_pwd" type="password"  autocomplete="off" prefix-icon="el-icon-unlock" v-on:keyup.enter.native="actionData" placeholder="請輸入密碼"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" class="login-btn" @click="actionData" :loading="$store.state.requestLoading">登錄</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import * as Api from './api';
export default {
  name: "login",
  data() {
    return {
      form: {
        login_account: '',
        login_pwd: ''
      },
      rules: {
        login_account: [
          { required: true, message: '请输入帐号', trigger: 'change' },
        ],
        login_pwd: [
          { required: true, message: '请输入密码', trigger: 'change' }
        ],
      }
    }
  },
  mounted() {

  },
  methods: {

    async actionData() {
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          let res = await Api.Login(this.form);

          if (res.code == '0') {
            this.$message.success(res.message);
             localStorage.setItem('user_info', JSON.stringify(res.target));
            localStorage.setItem('login_code', res.login_code);
            setTimeout(()=>{
              this.$router.push({ path: '/' });
            }, 1500);
          } else {
            this.$message.error(res.message);
          }
        }
      });
    }
  }
}
</script>

<style scoped>
  .login-box{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: absolute;
    z-index: 3;
    width: 400px;
    left: 50%;
    margin-left: -200px;
    top: 20%;
    background: hsla(0,0%,100%,.86);
    padding: 20px;
    border-radius: 3px;

  }
  .login-form {
    width: 100%;
  }
  .login-btn {
    width: 100%;
    cursor: pointer;
  }
  .login_logo{
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 40px;
    width: 100%;
    text-align: left;
  }
</style>
